.vendordiv1{
    display: flex;
    gap: 20px;
    height: 100%;
}
.headercar{
    margin-top: -20px;
}
.vendordiv2{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    height: 100%;
}
.inputven{
    font-weight: 400;
    color: black;
}
.vendorcol2a{
    width: 100%;
    height: 100%;
}

.itsubmt{
    margin-top: -10px;
}

.nogird{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.vendorcol1{
    width: 70%;
}
.vendorcol1a{
    width: 100%;
}
.itemGrp{
    width: 100%;
}
.formdiv1{
    display: flex;
    gap: 10px;
   
}
.formdivss{
    display: flex;
    justify-content: center;
    gap: 20%;
    padding: 10%;
    padding-top: -8%;
   
}

.formdivcol{
    display: flex;
    flex-direction: column;
    gap: 30px;
   
}
.formdiv1b{
    display: flex;
    gap: 10px;
    padding-top: 10px;
   
}
.formdiv1ba{
    display: flex;
    gap: 10px;
    padding-top: 10px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 10px;
   
}
.h5{
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    box-sizing: border-box;
}
.custom-width {
    width: 300px; /* or any desired width */
  }
  
  .custom-height {
    height: 130px; /* or any desired height */
  }

.roForm{
    width: 35%;
    height: 100px;
}
.formcol{
    width: 100%;
}
.custom-modal-card-width {
    max-width: 100%; /* Adjust the width as per your requirement */
    width: 100%;
    margin: auto; /* Centers the modal horizontally */
    text-align: center; 
  }
  .modal-content {
    max-width: 80%;
    width: 80%;
  }
  .custom-modal-content {
    width: 650px; /* Or whatever width you prefer */
    max-width: 650px; /* Ensure it doesn’t exceed screen width */
 }
 
.check1{
    margin-top: -16px;
}
.formcol1{
    width: 100%;
    display: flex;
    gap: 20px;
}
.formin{
    width:25%;
}
.formin1{
    width:55%;
}
.formradio{
    display: flex;
    align-items: center;
    justify-content: center;
}
.formin2{
    width:33%;
}
.navitem :hover{
    cursor: pointer;
}
.formin2ab{
    width:auto;
}
.formina{
    width:100%
}
.fomrcol2{
    width: 100%;
    display: flex;
}

.fomrcol23{

    display: flex;
    gap: 50px;
}
.formcol2a{
    width: 94.6%;
}
.rowForm{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.tabs{
    display: flex;
    justify-content: center;
    align-items: end ;
    gap: 20px;
}
.h5cus{
    border-bottom: 1px solid #ecf3fa;
}
.rowForm1{
    width: 100%;
    /* padding-left: 30px;
    padding-right: 30px; */
    padding-bottom: 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}
.formdiv1a{
    width: 50%;
}
.formgrp{
    width: 33%;
}
.full-size-input {
    width: 100%;     /* Make the input field take the full width of the cell */
    height: 100%;    /* Make the input field take the full height of the cell */
    border: none;    /* Remove borders (optional) */
    padding: 0;      /* Remove any padding */
    margin: 0;       /* Remove any margin */
    box-sizing: border-box; /* Ensure the width/height include borders and padding */
  }
  
.formgrp1{
    width: 33%;
}
.Container1{
    padding-right: 30px;
}
.pagenationvendorcat{
    display: flex;
    align-items: center;
    justify-content: center;
}
.vendordele{
    border: none;
    background-color: transparent;
    color: rgb(224, 0, 0);
}



.grid_container{
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 10px;
}

.grid-item {
   width: 16rem;  }
.vendordele1{
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    box-sizing: border-box;
    background-color: transparent;
    color: rgb(224, 0, 0);
}
.vendordele1a{
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background-color: transparent;
    color: rgb(224, 0, 0);
}
.vendorupdate{
    border: none;
    background-color: transparent;
    color:#7366ff;
}
.vendortrash{
    width: 30px;
    height: 20px;
}
.vendortrash1{
    width: 20px;
    height: 16px;
    margin-bottom: 2px;
}
.btncon{
    display: flex;
    margin-top: 25px;
    font-size: 16px;
    gap: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.input-no-animation:focus {
    outline: none;
    box-shadow: none;
    border-color: initial;
}
.buttonven{
    display: flex;
    align-items: left;
    justify-content: left;
}
.pagenation{
    display: flex;
    gap: 10px;
}
.vendorcol2{
    width: 30%;
}
/* .vendortbody{
    overflow: auto;
    display: block;
} */
.vendorthead{
    position: sticky;
    top: 0;
    z-index: 1;
} 
/* .vendortable{
    display: block;
    overflow: auto;
    height: 300px;
} */
table, th, td {
    border: 1px solid black;
    border-collapse: collapse;

  }
.selectdel{
    display: flex;
    width: 40%;
    align-items: center;
    justify-content: end;
    padding-right: 30px;
    box-sizing: border-box;
}
.is-invalid {
    border-color: red;
    background-color: #f8d7da;
}
  
.headercl{
    width: 60%;
}
.highlighted-text {
    background-color: yellow;
    font-weight: bold;
}

@media (max-width: 910px) {
    .vendordiv1{
        display: flex;
        flex-direction: column;
    }
    .vendorcol1{
        width: 100%;
    }
    .vendorcol1a{
        width: 100%;
    }
    .vendorcol2{
        width: 100%;
    }
    .vendorcol2a{
        width: 100%;
    }
}
@media (max-width: 810px){
    .vendorcol2a{
        width: 100%;
    }
}
@media (max-width:580px){
    .tabbed-car{
        width: 100%;
    }
    .nav{
        width: 100%;
    }
    .rowForm1{
        display: flex;
        flex-direction: column;
        padding-bottom: 0;
    }
    .formcol1{
        display: flex;
        flex-direction: column;
        width: 100%;
        gap:10px;
    }
    .formin2{
        width: 100%;
        margin-bottom: 0 !important;
    }
    .formin1{
        width: 100%;
    }
    .Container1{
        padding-right: 0;
    }
}
.tabdiv{
    display: flex;
    flex-direction: column;
}
.inputfoc[type=text]:focus {
    background-color: black;
    color : white !important;

  }
  .inputfoc[type=number]:focus {
    background-color: black;
    color : white !important;

  }
.inputfoc1:focus {
    background-color: black;
    color : white !important;

}
.label1{
    font-size: 12px;
    font-weight: 500;
    font-family: Rubik, sans-serif;
}
.h5title{
    font-size: 16px;
    font-family: Rubik, sans-serif;
    font-weight: 500;
}
.forminput{
    height: 30px;
}
.dataTableDiv{
    font-size: 12px;
}
.btn2 {
    font-size: 12px;
    background-color: #7366ff;
    display: flex;
    align-items: center;
    border-radius: 5px;
    justify-content: center;
    color: white;
    padding-left: 15px;
    padding-right: 15px;
    height: 25px;
    width: auto;
    border: none;
    transition: background-color 0.3s ease, transform 0.2s ease;  /* Smooth background change and click effect */
    cursor: pointer;
}
.btn1 {
    font-size: 12px;
    background-color: #e46960;
    border-radius: 5px;
    justify-content: center;
    display: flex;
    align-items: center;
    color: white;
    padding-left: 10px;
    padding-right: 10px;
    height: 25px;
    width: auto;
    border: none;
    transition: background-color 0.3s ease, transform 0.2s ease;  /* Smooth background change and click effect */
    cursor: pointer;
}
.btn1ab {
    display: flex;
    align-items: center;
    font-size: 12px;
    height: 25px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #e46960;
    border-radius: 5px;
    color: white;
    width: auto;
    border: none;
    transition: background-color 0.3s ease, transform 0.2s ease;  /* Smooth background change and click effect */
    cursor: pointer;
}
.btn1ab:hover {
    background-color: #c7554b;  /* Darker shade on hover */
    opacity: 0.85;
    transform: translateY(-2px);  /* Lift effect on hover */
}
.btn1a {
    font-size: 12px;
    background-color: #4160ff;
    border-radius: 12px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;
    color: white;
    height: 25px;
    width: auto;
    border: none;
    transition: background-color 0.3s ease, transform 0.2s ease;  /* Smooth background change and click effect */
    cursor: pointer;
}

.btn1:hover {
    background-color: #c7554b;  /* Darker shade on hover */
    opacity: 0.85;
    transform: translateY(-2px);  /* Lift effect on hover */
}

.btn1:active {
    transform: translateY(1px);  /* Pressed effect */
}
/* Hide arrows in Chrome, Safari, and newer Edge */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* btn2 - Enhanced */
.btn2 {
    font-size: 12px;
    background-color: #7366ff;
    display: flex;
    align-items: center;
    border-radius: 5px;
    justify-content: center;
    color: white;
    padding-left: 10px;
    padding-right: 10px;
    height: 25px;
    width: auto;
    border: none;
    transition: background-color 0.3s ease, transform 0.2s ease;  /* Smooth background change and click effect */
    cursor: pointer;
}
.btn2a {
    background-color: #7366ff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    height: 25px;
    font-size: 12px;
    color: white;
    width: auto;
    border: none;
    transition: background-color 0.3s ease, transform 0.2s ease;  /* Smooth background change and click effect */
    cursor: pointer;
}

.btn2a:hover {
    background-color: #211697;  /* Darker shade on hover */
    opacity: 0.9;
    transform: translateY(-2px);  /* Lift effect on hover */
}
.btn2:hover {
    background-color: #211697;  /* Darker shade on hover */
    opacity: 0.9;
    transform: translateY(-2px);  /* Lift effect on hover */
}

.btn2:disabled {
    pointer-events: none; /* Prevents hover and click events */
    cursor: not-allowed;  /* Shows a 'not-allowed' cursor to indicate it's disabled */
    opacity: 0.6;         /* Adjust opacity to indicate a disabled state */
}
.btn2:active {
    transform: translateY(1px);  /* Pressed effect */
}

/* Focus states for both buttons */
.btn2:focus, .btn1:focus {
    outline: none;  /* Remove default outline */
    border: 2px solid #0000ff;  /* Blue border */
    box-shadow: 0 0 10px rgba(0, 0, 255, 0.5);  /* Blue glow */
    opacity: 1;
}
 .selectin{
    border-color: black;
    border-radius: 5px;
 }
.popup {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 20px;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
}
.popup-inner {
    background-color: white;
    position: absolute;
    max-height: 500px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    width: 60%;
}
.popupform{
    height: 100%;
}
.table-container {
    z-index: 2;
    overflow-y: auto;   /* Enable scrolling when table exceeds height */
    max-height: 300px;
}
.popup-inner h2 {
    margin-top: 0;
}

.popup-inner label {
    display: block;
    margin-bottom: 10px;
}

.popup-inner input {
    width: 97%;
    color: black;
    border: none;
}
    
.popup-inner button[type="submit"] {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 5px;
}
    
.popup-inner button[type="submit"]:hover{
    background-color: #45a049;
}
    
.popup-inner button[type="submit"] {
    margin-top: 20px;
}
    
.popup-inner button[type="submit"]:disabled {
    background-color: #bfbfbf;
    color: #ffffff;
    cursor: not-allowed;
}
    
.popup-inner button[type="button"]:disabled {
    background-color: #bfbfbf;
    color: #ffffff;
    cursor: not-allowed;
}
.popbtn{
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: flex-end;
}

.popupform{
    margin-top: 20px;
}

.btn1l1 {
    font-size: 20px;
    background-color: #e46960;
    border-radius: 12px;
    color: white;
    height: 40px;
    font-size: 18px;
    width: 15rem;
    border: none;
    transition: background-color 0.3s ease, transform 0.2s ease;  /* Smooth background change and click effect */
    cursor: pointer;
}
.btn1al1 {
    font-size: 16px;
    background-color: #4160ff;
    border-radius: 12px;
    margin-top: 10px;
    color: white;
    height: 30px;
    width: 100px;
    border: none;
    transition: background-color 0.3s ease, transform 0.2s ease;  /* Smooth background change and click effect */
    cursor: pointer;
}
    
.btn1l1:hover {
    background-color: #c7554b;  /* Darker shade on hover */
    opacity: 0.85;
    transform: translateY(-2px);  /* Lift effect on hover */
}

.btn1l1:active {
    transform: translateY(1px);  /* Pressed effect */
}

/* btn2 - Enhanced */
.btn2l1 {
    font-size: 20px;
    background-color: #7366ff;
    border-radius: 12px;
    color: white;
    font-size: 18px;
    height: 40px;
    width: 15rem;
    border: none;
    transition: background-color 0.3s ease, transform 0.2s ease;  /* Smooth background change and click effect */
    cursor: pointer;
}

.btn2l1:hover {
    background-color: #211697;  /* Darker shade on hover */
    opacity: 0.9;
    transform: translateY(-2px);  /* Lift effect on hover */
}

.btn2l1:disabled {
    pointer-events: none; /* Prevents hover and click events */
    cursor: not-allowed;  /* Shows a 'not-allowed' cursor to indicate it's disabled */
    opacity: 0.6;         /* Adjust opacity to indicate a disabled state */
}
.btn2l1:active {
    transform: translateY(1px);  /* Pressed effect */
}

/* Focus states for both buttons */
.btn2l1:focus, .btn1l1:focus {
    outline: none;  /* Remove default outline */
    border: 2px solid #0000ff;  /* Blue border */
    box-shadow: 0 0 10px rgba(0, 0, 255, 0.5);  /* Blue glow */
    opacity: 1;

}.table_td_div{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
}
.vengirdbtn{
    display: flex;
    width: 50%;
    gap: 10px;
}
.vengirdbtn1{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 30px;
    padding-right: 30px;
    width: auto;
    gap: 10px;
}
.popcls{
    display: flex;
    width: 100%;
}
.clsico{
    font-weight: 600;
    width: 20px;
    height: 20px;
}
.popclsdiv1{
    width: 50%;
}
.popclsdiv2{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.popclsbtn{
    background-color: transparent;
    border: none;
}
.subdiv{
    display: flex;
}
#whcategory {
    font-size: 11px;
    border-radius: 0;
    padding-left: 5px;
    padding-top: 0;
    padding-bottom: 0;
    border-color: #fff;
    padding-right: 5px;
}
#whcategory option{
    font-size: 10px;
}


.itemspec{
    width: 16%;
}

.itemdiv{
    width: 25%;
}
.itemware{
    width: 50%;
}